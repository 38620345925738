import Navbar from "./Navbar";
import Footer from "./Footer";
import Coma from '../assets/img/story.png';
import Comb from '../assets/img/com-b.png';
import Comc from '../assets/img/com-c.png';
//import CommunityImage from '../assets/img/community.png';
//import Tweets from '../assets/img/tweets.png';
//import { ArrowRight } from "react-bootstrap-icons";
import { Twitter, Facebook,Whatsapp} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import StoryDetails from '../assets/img/story-details.png';
const Story = () => {
    return (
        <div className="app">
            <header>
                <Navbar/>
            </header>
            <section className="py-9 mt-5">
                <div className="container mt-5">
                    <div className="row mt-5">
                        <div className="col-sm-12 mt-5">
                            <h1 className="mt-5 mb-5">Payday laons anyday</h1>
                            <img className="LoanImg" src={StoryDetails} alt="" />
                            <p className="loans-text mt-5">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
Proin pharetra nonummy pede. Mauris et orci. Aenean nec lorem. In porttitor. Donec laoreet nonummy augue. Suspendisse dui purus, scelerisque at, vulputate vitae, pretium mattis, nunc. Mauris eget neque at sem venenatis eleifend.
Ut nonummy. Fusce aliquet pede non pede. Suspendisse dapibus lorem pellentesque magna. Integer nulla. Donec blandit feugiat ligula. Donec hendrerit, felis et imperdiet euismod, purus ipsum pretium metus, in lacinia nulla nisl eget sapien. Donec ut est in lectus consequat consequat.


                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-9 mt-5">
                <div className="container">
                    <div className="row">
                        <h3 className="mt-5 mb-5">Stories from our community.</h3>
                        <div className="col-sm-12 d-flex flex-sm-row flex-column align-items-center">
                        <div className="col-sm-4 com-img-container">
                                <Link to={'/story'}>
                                    <h3>Credit score <br /> Know how</h3>
                                </Link>
                                    <img className="com" src={Coma} alt="" />
                                </div>
                            <div className="col-sm-4 ms-3 me-3 com-img-container">
                            <Link to={'/story'}>
                                <h3>Lumwana mine <br />& us</h3>
                                </Link>
                                <img className="com" src={Comb} alt="" />
                            </div>
                            <div className="col-sm-4 com-img-container">
                            <Link to={'/story'}>
                                <h3>Focus on <br /> what matters</h3>
                                </Link>
                                <img className="com" src={Comc} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="py-9 mt-5">
           <div className="container mt-5">
               <div className="row mt-5">
                   <center className="mt-5">
                       <h1>Follow us;</h1>
                   <div className="col-sm-9 mt-5 contact-icons">
                       <span className="ms-5"><Twitter className="contactIcon"/> @quickerpayouts</span>
                       <span className="ms-5"><Facebook className="contactIcon"/> Qualis Digital Loans</span>
                       <span className="ms-5"><Whatsapp className="contactIcon"/> +260 971 234 567</span>
                   </div>
                   </center>
               </div>
           </div>
       </section>
            <Footer/>
        </div>
    );
}

export default Story;