import logo from '../assets/img/qualis-logo.png';
import HomeIcon from '../assets/img/home-icon.png';
import PaymentIcon from '../assets/img/payment-icon.png';
import AccountIcon from '../assets/img/account-icon.png';
import ReportIcon from '../assets/img/report-icon.png';
import SettingIcon from '../assets/img/setting-icon.png';
import { NavLink, Link } from 'react-router-dom';
import PROFILE from '../assets/img/profile.png';
import logout from '../assets/img/logout.png';
const Dashnav = () =>{
    return (<nav className="navbar navbar-vertical navbar-expand-md navbar-light dashboard-nav" id='sidebar'>
    <div className="">
        <button className="navbar-toggler collapsed" type='button' data-bs-toggle='collapse' data-bs-target='#sidebarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
        <span className="navbar-toggler-icon mt-2">
        <span className="navbar-toggler-bar bar1"></span>
        <span className="navbar-toggler-bar bar2"></span>
        <span className="navbar-toggler-bar bar3"></span>
        </span>
        </button>
        <div>
            <Link to={'/'} className="navbar-brand">
                <img className='navbar-brand-img mx-auto' src={logo} alt="" />
            </Link>
        </div>
        <div className="navbar-user d-md-none"></div>
        <div className="navbar-collapse collapse mt-5" id='sidebarCollapse'>
            <ul className="navbar-nav navbar-dashboard">
                
                <li className="nav-item">
                <NavLink to={'/dashboard'} className={({isActive})=>(isActive ? "link-active nav-link align-items-center d-flex":"link-unactive nav-link align-items-center d-flex")}>
                        <span className='me-3'><img src={HomeIcon} alt="" /></span>
                        Home
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/payments'} className={({isActive})=>(isActive ? "link-active nav-link align-items-center d-flex":"link-unactive nav-link align-items-center d-flex")}>
                        <span className='me-3'><img src={PaymentIcon} alt="" /></span>
                        Payment
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/account'} className={({isActive})=>(isActive ? "link-active nav-link align-items-center d-flex":"link-unactive nav-link align-items-center d-flex")}>
                        <span className='me-3'><img src={AccountIcon} alt="" /></span>
                        Account
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/reports'} className={({isActive})=>(isActive ? "link-active nav-link align-items-center d-flex":"link-unactive nav-link align-items-center d-flex")}>
                        <span className='me-3'><img src={ReportIcon} alt="" /></span>
                        Reports
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/settings'} className={({isActive})=>(isActive ? "link-active nav-link align-items-center d-flex":"link-unactive nav-link align-items-center d-flex")}>
                        <span className='me-3'><img src={SettingIcon} alt="" /></span>
                        Settings
                    </NavLink>
                </li>
                <li className="nav-item mt-5 mb-5">
                    <NavLink to={'/'} className={({isActive})=>(isActive ? "link-active nav-link align-items-center d-flex":"link-unactive nav-link align-items-center d-flex")}>
                        <span className='me-3'><img src={logout} alt="" /></span>
                        Logout
                    </NavLink>
                </li>
            </ul>
            <div className="d-flex flex-sm-row flex-column mt-5">
                <img src={PROFILE} alt="" />
                <div className='ms-3 nav-bottom'>
                    <span>Kangwa</span>
                    <div>mfumu@live.co.za</div>
                </div>
            </div>
        </div>
    </div>
</nav>)
}
export default Dashnav;