import Navbar from "./Navbar";
import imageHeader from '../assets/img/woman.png';
import { Twitter, Facebook,Whatsapp} from "react-bootstrap-icons";
import Footer from "./Footer";
const  Contact = () => {
  return (
   <div className="app">
       <header>
            <Navbar/>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5 header-text align-items-center mt-5 d-flex flex-sm-row flex-column">
                            <h1 className="header-title">Get in <span>touch</span><br />with us
                            <p>Contact our support team, email us or whatsapp and we will help you achieve more today.</p>
                            </h1>
                        </div>
                        <div className="col-sm-7 mt-5 align-items-center d-flex flex-sm-row flex-column">
                            <img className="image-header" src={imageHeader} alt="" />
                        </div>
                    </div>
                </div>
            </div>
       </header>
       <section className="py-9 mt-5">
           <div className="container">
               <div className="row forms">
                   <h3>Contact</h3>
                   <div className="col-sm-6 mt-5">
                       <input type="text" className="form-control" placeholder="What are your names"/>
                   </div>
                   <div className="col-sm-6 mt-5">
                       <input type="text" className="form-control" placeholder="Enter your email address"/>
                   </div>
                   <div className="col-sm-12 mt-5">
                       <textarea className="form-control" name="" id="" cols="30" rows="10" placeholder="Let us know how we can help you get payouts quicker"></textarea>
                   </div>
                   <button className="btn btn-primary btn-sm mt-3 contact-btn">Send</button>
               </div>
           </div>
       </section>
       <section className="py-9 mt-5">
           <div className="container mt-5">
               <div className="row mt-5">
                   <div className="col-sm-3 mt-5">
                       <h4>Follow us;</h4>
                   </div>
                   <div className="col-sm-9 mt-5 contact-icons">
                       <span className="ms-5"><Twitter className="contactIcon"/> @quickerpayouts</span>
                       <span className="ms-5"><Facebook className="contactIcon"/> Qualis Digital Loans</span>
                       <span className="ms-5"><Whatsapp className="contactIcon"/> +260 971 234 567</span>
                   </div>
               </div>
           </div>
       </section>
       <Footer/>
   </div>
  );
}

export default Contact;