import footerLogo from '../assets/img/footer-logo.png';
import { Twitter, Facebook,Whatsapp,Telephone} from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const Footer = () =>{
    return(<footer className="mt-5 py-9">
    <section className="py-9 footer">
        <div className="container mt-5">
            <div className="row mt-5">
                <div className="col-sm-2 mt-5">
                    <h5>HOME</h5>
                    <ul className="footer-link ps-0">
                        <li>
                            <Link to={'/'}>Persoan loans</Link>
                        </li>
                        <li>
                            <Link to={'/'}>Payday loan</Link>
                        </li>
                        <li>
                            <Link to={'/'}>SME loans</Link>
                        </li>
                        <li>
                            <Link to={'/'}>Web app</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-2 mt-5">
                    <h5>ABOUT US</h5>
                    <ul className="footer-link ps-0">
                        <li>
                            <Link to={'/'}>Sectors</Link>
                        </li>
                        <li>
                            <Link to={'/'}>Fraud guide</Link>
                        </li>
                        <li>
                            <Link to={'/'}>Legal</Link>
                        </li>
                        <li>
                            <Link to={'/'}>About</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-2 mt-5">
                    <h5>COMMUNITY</h5>
                    <ul className="footer-link ps-0">
                        <li>
                            <Link to={'/'}>Social community</Link>
                        </li>
                        <li>
                            <Link to={'/'}>Blog</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-2 mt-5">
                    <h5>CONTACT US <Telephone className="phone-link"/></h5>
                    <ul className="footer-link ps-0">
                        <li>
                            <Link to={'/'}>Inquiries</Link>
                        </li>
                    </ul>
                    <div>inquiries@qualis.co</div>
                    <div>+260961475400</div>
                    <div>08h00 - 17h00</div>
                </div>
                <div className="col-sm-2 mt-5">
                    <h5></h5>
                    <ul className="footer-link ps-0">
                        <li>
                            <Link to={'/'}>Support</Link>
                        </li>
                    </ul>
                    <div>support@qualis.co</div>
                    <div>+260961475400</div>
                    <div>08h00 - 17h00</div>
                </div>
                <div className="col-sm-2 mt-5">
                    <h5></h5>
                    <ul className="footer-link ps-0">
                        <li>
                            <Link to={'/'}>Technical</Link>
                        </li>
                    </ul>
                    <div>tech@qualis.co</div>
                    <div>+260961475400</div>
                    <div>08h00 - 17h00</div>
                </div>
                <hr className="mt-5"/>
                <div className="mt-5 col-sm-12 mb-5">
                    <div className="row">
                        <div className="col-sm-2 d-flex align-items-center">
                            <img src={footerLogo} alt="" />
                        </div>
                        <div className="col-sm-4 d-flex align-items-center">
                            <p>2022 Qualis Digital Loans</p>
                        </div>
                        <div className="col-sm-2 botton-social d-flex align-items-center">
                            <span className="ms-3"><Twitter/></span>
                            <span className="ms-3"><Facebook/></span>
                            <span className="ms-3"><Whatsapp/></span>
                        </div>
                        <div className="col-sm-4 d-flex align-items-center">
                            <span className="ms-5"><Link className="contact-link-footer" to={'/'}>Contact Us</Link></span>
                            <span className="ms-5">Legal</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</footer>)
}
export default Footer;