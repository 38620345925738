import logo from '../assets/img/login-logo.png';
import {Link} from 'react-router-dom';
import LoginImage1 from '../assets/img/signup1.png';
import LoginImage2 from '../assets/img/signup2.png';

const Signup = () =>{
    return (
        <div className="app">
            <div className="col-sm-12 d-flex flex-sm-row flex-column align-items-center vh-100">
                <div className="col-sm-6 d-flex flex-sm-row flex-column left-login align-items-center vh-100">
                <div className="col-sm-12 ">
                    <h1 className="mt-5 apply-loan login-title">Closer to quick<span>payouts</span></h1>
                    <div className="">
                        <div className="">
                            <div className="">
                                <h4 className="mt-5 mb-5 qualifies login-title">Let's get you startedwith our easy sign up process</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 mt-5 align-items-center d-flex" >
                    <img className="image-header double-img" src={LoginImage1} alt="" />
                    <img className="image-header mt-5 ms-4 double-img" src={LoginImage2} alt="" />
                    </div>
                    <div className="col-sm-12 d-flex flex-sm-row flex-column align-items-center mt-5">
                        <img src={logo} alt="" className='float-start'/>
                        <div className='float-end col-sm-12 text-end footer-text-form'>&copy; 2022 Qualis Digital Loans. All rights reserved.</div>
                    </div>
                </div>
                </div>
                <div className="col-sm-6 right-login align-items-center forms">
                    <div className="col-sm-6 login-form">
                    <div className="col-sm-12 mb-5">
                        <h2 className='mb-5'>Create an account</h2>
                    </div>
                    <div className="col-sm-12">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" className='form-control'/>
                    </div>
                    <div className="col-sm-12">
                        <label htmlFor="email">E-mail</label>
                        <input type="text" name="email" id="" className='form-control' placeholder='email@email.com or q-12345' />
                    </div>
                    <div className="col-sm-12">
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password" id="password" className='form-control'/>
                    </div>
                    <div className="col-sm-12">
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password" id="" className='form-control' placeholder='password'/>
                        <div className="col-sm-12">
                            <Link className='forgot-password' to={'/'}>Forgot password?</Link>
                        </div>
                    </div>
                    <div className="col-sm-12 mt-5">
                        <buttton className="btn btn-primary login-button">Create Account</buttton>
                        <div className='mt-3'>Already have an account? <Link className='signup-link' to={'/login'}>Log In</Link></div>
                    </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Signup;