import Navbar from "./Navbar";
import Footer from "./Footer";
import manHome from '../assets/img/man-home.png';
import womanHome from '../assets/img/woman-home.png';
import { ArrowRight } from "react-bootstrap-icons";
import Player from '../assets/img/play-video.png';
const Home =()=>{
    return(
        <div className="app">
            <header>
                <Navbar/>
                <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5 header-text align-items-center mt-5 d-flex flex-sm-row flex-column">
                            <h1 className="header-title">How <span>Payday</span><br />loans work
                            <p>When you need a Digital Financial Partner to help you <br />achieve more. Borrow and get things done quicker.</p>
                            <button className="btn btn-primary sign-up-btn">Sign Up <ArrowRight/></button>
                            </h1>
                        </div>
                        <div className="col-sm-7 mt-5 align-items-center d-flex">
                            <img className="image-header double-img" src={womanHome} alt="" />
                            <img className="image-header mt-5 ms-4 double-img" src={manHome} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            </header>
            <section className="py-9 mt-5">
                <div className="container mt-5">
                    <div className="row mt-5">
                        <div className="align-items-center">
                            <center>
                                <button className="btn btn-primary btn-salary-advance">Salary Advance</button>
                                <h1 className="mt-5 apply-loan">Apply for a Payday loan & get <br /><span>payouts</span> quicker.</h1>
                                <br/>
                                <div className="mt-5">
                                    <div className="mt-5">
                                        <div className="mt-5">
                                            <h4 className="mt-5 mb-5 qualifies">Who qualifies for a Salary Loan</h4>
                                            <p className="loan-qualify">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin pharetra nonummy pede. Mauris et orci. Aenean nec lorem.</p>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="mt-5">
                                    <div className="mt-5">
                                        <h3 className="mb-5">How to apply online</h3>
                                    </div>
                                </div>
                            </center>
                            <div className="video-apply p-5">
                                <div className="overlay"></div>
                                <div className="row">
                                    <div className="col-sm-4 d-flex flex-sm-row flex-column align-items-center p-5">
                                        <h4 className="how-to-apply">How to do <br /><span>Apply for a loan using the Qualis Web App.</span></h4>
                                    </div>
                                    <div className="col-sm-8 d-flex flex-sm-row flex-column align-items-center p-5">
                                        <img className="play-video" src={Player} alt="" />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="sme-loans mt-5 mb-5">
                                <button className="loans-sme btn btn-primary btn-salary-advance">SME Loans <ArrowRight/></button>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}
export default Home;