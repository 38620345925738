import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './screens/Contact';
import Home from './screens/Home';
import Loan from './screens/Loan';
import About from './screens/About';
import Community from './screens/Community';
import Story from './screens/Story';
import Login from './screens/Login';
import Signup from './screens/Signup';
import Dashbaord from './screens/Dashboard';
import Dashbaordhome from './screens/Dashboard-home';
import Payments from './screens/Payment';
import Reports from './screens/Reports';
import Account from './screens/Account';
import Settings from './screens/Settings';
function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/contact-us' element={<Contact/>}/>
          <Route exact path='/loan' element={<Loan/>}/>
          <Route exact path='/about-us' element={<About/>}/>
          <Route exact path='/community' element={<Community/>}/>
          <Route exact path='/story' element={<Story/>}/>
          <Route exact path='/login' element={<Login/>}/>
          <Route exact path='/signup' element={<Signup/>}/>
          <Route exact path='/dashboard' element={<Dashbaord/>}/>
          <Route exact path='/dashboard-home' element={<Dashbaordhome/>}/>
          <Route exact path='/payments' element={<Payments/>}/>
          <Route exact path='/reports' element={<Reports/>}/>
          <Route exact path='/account' element={<Account/>}/>
          <Route exact path='/settings' element={<Settings/>}/>
        </Routes>
      </div>
    </Router>
  );
}
export default App;