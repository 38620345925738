import React, {useState} from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import manHome from '../assets/img/community-man.png';
import womanHome from '../assets/img/community-woman.png';
import { ArrowRight } from "react-bootstrap-icons";
import {Tabs,Tab} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import hourGlass from '../assets/img/h-glass.png';
import Finger from '../assets/img/finger.png';
import Fire from '../assets/img/fire.png';
import GrayMan from '../assets/img/gray-man.png';
import Personal from '../assets/img/personal.png';
import SME from '../assets/img/sme.png';
import BlackLogo from '../assets/img/black-logo.png';
import Apple from '../assets/img/apple.png';
import GooglePlay from '../assets/img/google-play.png';
//import { ArrowRight } from "react-bootstrap-icons";
const Loan =()=>{
    const[getamount, setAmount] = useState(5000);
    const[weeks, setWeeks] = useState(7);
    return(
        <div className="app">
            <header>
                <Navbar/>
                <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5 header-text align-items-center mt-5 d-flex flex-sm-row flex-column">
                            <h1 className="header-title"><span>Lets grow further</span><br />together.
                            <p>When you need a Digital Financial Partner to help you <br />achieve more. Borrow and get things done quicker.</p>
                            <button className="btn btn-primary sign-up-btn">Sign Up <ArrowRight/></button>
                            </h1>
                        </div>
                        <div className="col-sm-7 mt-5 align-items-center d-flex">
                            <img className="image-header double-img" src={manHome} alt="" />
                            <img className="image-header mt-5 ms-4 double-img" src={womanHome} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            </header>
            <section className="py-9 calculator">
                <div className="container">
                    <div className="row ">
                        <div className="tabsx">
                            <Tabs className="mb-3" defaultActivityKey="home">
                                <Tab eventKey="New" title="New Customer">
                                    <div className="col-sm-12 d-flex flex-sm-row flex-column align-items-center mt-5">
                                        <div className="col-sm-4">
                                            <p>Amount (zmw)</p>
                                            <h4>How much do you need</h4>
                                            <div className="main-get-amountn mb-5">
                                                <div className="inner-get-amount">{getamount}</div>
                                            </div>
                                            <RangeSlider
                                                value={getamount}
                                                onChange = {e=>setAmount(e.target.value)}
                                                min={500}
                                                max={100000}
                                                step={50}
                                            />
                                            <div className="mt-5">
                                                <p>Select loan type</p>
                                                <p className="btn btn-primary sign-up-btn">Short Term (Upto K5000)</p>
                                                <p className="btn btn-primary sign-up-btn-rev ms-1"> Long term (Upto K200,000)</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-5 ms-5">
                                            <p>Duration (weeks)</p>
                                            <h4>How long do you want it for?</h4>
                                            <div className="main-get-amount mb-5">
                                                <div className="inner-get-amount">{weeks}</div>
                                            </div>
                                            <RangeSlider
                                                value={weeks}
                                                onChange = {e=>setWeeks(e.target.value)}
                                                min={1}
                                                max={48}
                                                step={1}
                                            />
                                            <div className="mt-5">
                                                <p>You pay back per Month</p>
                                                <p className="btn btn-primary sign-up-btn">K235/m</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 ms-4 align-items-center">
                                                <div style={{postion:'relative'}}>
                                                    <p style={{float:'right'}} className="btn btn-primary sign-up-btn">Get Loan</p>
                                                </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Returning" title="Returning Customer" style={{color:'#fff !important'}}>
                                    <div>jh dihld</div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-9">
                <div className="container">
                    <div className="row">
                        <center className="mt-5 mb-5">
                            <h1 className="section-title">Loans made simpler, faster with <br /><span>payouts</span> at your pace.</h1>
                        </center>
                        <div className="col-sm-4 d-flex flex-sm-row flex-column align-items-center mt-5">
                            <img src={hourGlass} alt="" />
                            <div className="col-sm-7 ms-5">
                                <h3>When you need it!</h3>
                                <span>Quick personal and SME loans to different people from various sectors.</span>
                            </div>
                        </div>
                        <div className="col-sm-4 d-flex flex-sm-row flex-column align-items-center mt-5">
                            <img src={Finger} alt="" />
                            <div className="col-sm-7 ms-5">
                                <h3>Digital access!</h3>
                                <span>Access our loans on our website or download our app. Get payouts your way.</span>
                            </div>
                        </div>
                        <div className="col-sm-4 d-flex flex-sm-row flex-column align-items-center mt-5">
                            <img src={Fire} alt="" />
                            <div className="col-sm-8 ms-5">
                                <h3>Secure and Convinient!</h3>
                                <span>Secure and no hidden costs. With realtime to same day payouts.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-9 mt-5 loan-types">
                <div className="container">
                    <div className="row">
                        <center className="mb-5">
                        <h1 style={{color:'#fff'}}>Payouts your, way.</h1>
                        </center>
                        <div className="col-sm-12 d-flex flex-sm-row flex-column align-items-center mt-5">
                            <div className="col-sm-4 align-items-center payout-img graym d-flex flex-sm-row flex-column">
                                <img src={GrayMan} alt="" />
                                <center>
                                    <div className="graym-text align-items-center col-sm-12">
                                            <h1>Payday loans</h1>
                                            <ArrowRight className="feature-arrow"/>
                                    </div>
                                </center>
                            </div>
                            <div className="col-sm-4 align-items-center ms-5 me-5 payout-img">
                                <img src={Personal} alt="" />
                                <div className="text-lower align-items-center">
                                    <h1>Personal Loans</h1>
                                </div>
                            </div>
                            <div className="col-sm-4 align-items-center payout-img">
                                <img src={SME} alt="" />
                                <div className="text-lower align-items-center">
                                    <h1>SME Loans</h1>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <section className="py-9 mt-5 mb-5">
                <div className="container mt-5 mb-5">
                    <div className="row mt-5 mb-5">
                        <div className="col-sm-12 align-items-center d-flex flex-sm-row flex-column mt-5 mb-5">
                            <div className="col-sm-6"></div>
                            <div className="col-sm-6 align-self-end">
                                <h1 className="section-title">Loans and payouts <br /> made<span> simplerrr....</span></h1>
                            </div>
                        </div>
                        <div className="col-sm-12 align-items-center d-flex flex-sm-row flex-column mt-5 mb-5">
                            <div className="col-sm-6"></div>
                            <div className="col-sm-4 align-items-center d-flex flex-sm-row flex-column">
                                <img className="black-logo" src={BlackLogo} alt="" />
                                <div className="ms-5">
                                    <p style={{color:'#000'}}>Download our app to access low interest loans quicker than you can imagine.</p>
                                    <img src={Apple} alt="" />
                                    <img src={GooglePlay} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}
export default Loan;