import Navbar from "./Navbar";
import Footer from "./Footer";
import Coma from '../assets/img/com-a.png';
import Comb from '../assets/img/com-b.png';
import Comc from '../assets/img/com-c.png';
import CommunityImage from '../assets/img/community.png';
import Tweets from '../assets/img/tweets.png';
import { ArrowRight } from "react-bootstrap-icons";
import { Twitter, Facebook,Whatsapp} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
const Community = () => {
    return (
        <div className="app">
            <header>
                <Navbar/>
                    <div className="page-header">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-5 header-text align-items-center mt-5 d-flex flex-sm-row flex-column">
                            <h1 className="header-title">A <span>community</span><br /> that gets things <br /> done quick
                            <p>Contact our support team, email us or whatsapp and we will help you achieve more today.</p>
                            </h1>
                        </div>
                        <div className="col-sm-7 mt-5 align-items-center d-flex flex-sm-row flex-column">
                            <img className="image-header" src={CommunityImage} alt="" />
                        </div>
                            </div>
                        </div>
                    </div>
            </header>
            <section className="py-9 mt-5">
                <div className="container">
                    <div className="row">
                        <h3 className="mt-5 mb-5">Stories from our community.</h3>
                        <div className="col-sm-12 d-flex flex-sm-row flex-column align-items-center">
                        
                                <div className="col-sm-4 com-img-container">
                                <Link to={'/story'}>
                                    <h3>Payday loans <br /> anyday</h3>
                                </Link>
                                    <img className="com" src={Coma} alt="" />
                                </div>
                            <div className="col-sm-4 ms-3 me-3 com-img-container">
                            <Link to={'/story'}>
                                <h3>Lumwana mine <br />& us</h3>
                                </Link>
                                <img className="com" src={Comb} alt="" />
                            </div>
                            <div className="col-sm-4 com-img-container">
                            <Link to={'/story'}>
                                <h3>Focus on <br /> what matters</h3>
                                </Link>
                                <img className="com" src={Comc} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-9 twittes">
                <div className="container mt-5">
                    <div className="row mt-5">
                        <h2 className="mb-5">Recent Tweets</h2>
                        <div className="col-sm-12 tweets d-flex flex-sm-row flex-column align-items-center">
                            <div className="col-sm-4 tweets-wrapper">
                                <img className="tweets-img" src={Tweets} alt="" />
                            </div>
                            <div className="col-sm-3 ms-5">
                                <b><p>@quickerpayouts</p></b>
                                <p className="loans-text">
                                Qualis Digital Loans will never ask for your passwords, pins and any other confidential information. Any email, message or correspondence without an official Qualis Digital loans email should be reported immediately.
                                </p>
                            </div>
                            <div className="col-sm-3 ms-5">
                                <div>
                                    <p className="loans-text">If you suspect any activity suspect to be fraudulent kindly contact the following numbers.</p>
                                </div>
                                <button className="btn btn-primary other-btn-x">Report Fraud <ArrowRight/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-9 mt-5">
           <div className="container mt-5">
               <div className="row mt-5">
                   <center className="mt-5">
                       <h1>Follow us;</h1>
                   <div className="col-sm-9 mt-5 contact-icons">
                       <span className="ms-5"><Twitter className="contactIcon"/> @quickerpayouts</span>
                       <span className="ms-5"><Facebook className="contactIcon"/> Qualis Digital Loans</span>
                       <span className="ms-5"><Whatsapp className="contactIcon"/> +260 971 234 567</span>
                   </div>
                   </center>
               </div>
           </div>
       </section>
            <Footer/>
        </div>
    );
}

export default Community;