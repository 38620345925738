import Navbar from "./Navbar";
import imageHeader from '../assets/img/about.png';
import aboutImage from '../assets/img/about-b.png';
//import { Twitter, Facebook,Whatsapp} from "react-bootstrap-icons";
import Footer from "./Footer";
import { ArrowRight } from "react-bootstrap-icons";
const  About = () => {
  return (
   <div className="app">
       <header>
            <Navbar/>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5 header-text align-items-center mt-5 d-flex flex-sm-row flex-column">
                            <h1 className="header-title">Your relentless <br /> Digital Financial <br /><span>partner</span>
                            <p>Our approach is to create a digital loan environment that helps you access money quicker and simpler, but more importantly support you as you grow with us.</p>
                            </h1>
                        </div>
                        <div className="col-sm-7 mt-5 align-items-center d-flex flex-sm-row flex-column">
                            <img className="image-header" src={imageHeader} alt="" />
                        </div>
                    </div>
                </div>
            </div>
       </header>
       <section className="py-9">
           <div className="container mt-5">
               <div className="row mt-5">
                   <div className="col-sm-12 mt-5 align-center d-flex flex-sm-row flex-column">
                       <div className="col-sm-6 d-flex flex-sm-row flex-column align-items-center">
                           <div className="col-sm-6">
                                <img className="aboutImage" src={aboutImage} alt="" />
                           </div>
                           <div className="col-sm-6 d-flex flex-sm-row flex-column align-items-center about-text-1">
                                <div className="col-sm-12">
                                    <h2>CIVIL SERVANTS</h2>
                                    <br />
                                    <div>
                                    We offer personal & payday loans to <br /> civil servants in the following sectors;<br />

                                    Government <br />
                                    Education <br />
                                    Health <br />
                                    Legal <br />
                                    Defence wings <br />

                                    </div>
                                </div>
                           </div>
                       </div>
                       <div className="col-sm-6 align-items-center d-flex flex-sm-row flex-column">
                           <div className="col-sm-12 other">
                                <h1>No matter who & where you are, <span>get payouts quicker</span>  than you can think.</h1>
                                <button className="btn btn-primary btn-sm other-btn">Other sectors <ArrowRight/></button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </section>
       <section className="py-9">
           <div className="container mt-5">
               <div className="row mt-5">
                   <div className="col-sm-12 green-section mt-5 align-items-center float-start d-flex flex-sm-row flex-column ">
                       <div className="col-sm-4 float-start">
                           <div>
                               <h1>Fraud Guide</h1>
                               <p className="mt-5">Qualis Digital Loans will never ask for your passwords, pins and any other confidential information. Any email, message or correspondence without an official Qualis Digital loans email should be reported immediately.</p>
                           </div>
                       </div>
                       <div className="col-sm-4 ms-5 float-start">
                           <h4>How to protect yourself</h4>
                           <ol>
                               <li>Never share your password or one-time-pin with anyone.</li>
                               <li>More guidelings here.....</li>
                           </ol>
                       </div>
                       <div className="col-sm-4 ms-5 float-start">
                           <p>If you suspect any activity suspect to be fraudulent kindly contact the following numbers.</p>
                           <button className="btn btn-primary other-btn-x">Report Fraud <ArrowRight/></button>
                       </div>
                   </div>
               </div>
           </div>
       </section>
       <section className="py-9">
           <div className="container mt-5">
               <div className="row mt-5 about-xs">
                    <center className="mb-5">
                        <h1>About Qualis Digital Loans</h1>
                    </center>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p> <br />
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
               </div>
           </div>
       </section>
       <Footer/>
   </div>
  );
}

export default About;