import React from 'react';
//import Search from '../assets/img/search.png';
import { Search, Bell } from 'react-bootstrap-icons';
import FNB from '../assets/img/fnb.png';
import Dashnav from './Dashnav';
import VISA from '../assets/img/visa.png';
import MasterCard from '../assets/img/master-card.png';
import MTN from '../assets/img/mtn.png';
import AIRTEL from '../assets/img/airtel.png';
import { Link} from 'react-router-dom';
import PROFILE from '../assets/img/profile.png';
import APPLY from '../assets/img/apply.png';
const Account = () => {
    return (
        <div className="app d-flex flex-sm-row flex-column">
            <Dashnav/>
            <div className="main-content">
                <div className="col-lg-12 float-end search-md">
                    <div className="input-group float-end">
                    <div className="float-end ms-1 me-2">
                        <Bell/>
                    </div>
                    <span className="input-group-append float-end">
                            <button className="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5" type="button">
                                <Search/>
                            </button>
                        </span>
                        <input type="text" name="searc" id="" className='form-control float-end dash-search border rounded-pill' placeholder='search here....'/>
                    </div>
                </div>
                <div className="col-12 col-lg-12 d-flex flex-sm-row flex-column">
                <div className="col-12 col-lg-3 left-dashboard container">
                        <h1 className='dashboard-title mb-4'>Hello, <span>Kangua</span></h1>
                        <div className="col-lg-12">
                            <p>Account Number</p>
                            <h4>q-0012345</h4>
                        </div>
                        <h1 className='mt-5 mb-5'>Repayment Management</h1>
                        <div className="col-lg-11 mt-5">
                            <div className=" mt-5">
                                <h4>Saved Cards</h4>
                                <div className="col-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-3">VISA</div>
                                        <div className="col-lg-6 mt-3">**** **** **** 4321</div>
                                        <div className="col-lg-6 mt-3">VISA</div>
                                        <div className="col-lg-6 mt-3">**** **** **** 4321</div>
                                        <div className="col-lg-6 mt-3">Master Card(Virtual)</div>
                                        <div className="col-lg-6 mt-3">**** **** **** 4321</div>
                                        <div className="col-lg-6 mt-5"><Link  to={'/'} className="add-card-mobile">Add Card</Link></div>
                                        <div className="col-lg-6 mt-5 d-flex flex-sm-row flex-column">
                                            <img src={MasterCard} alt="" />
                                            <img className='ms-3' src={VISA} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-11 mt-5">
                            <div className=" mt-5">
                                <h4>Mobile Number</h4>
                                <div className="col-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-3">MTN</div>
                                        <div className="col-lg-6 mt-3">+260 96***** 400</div>
                                        <div className="col-lg-6 mt-3">Airtel</div>
                                        <div className="col-lg-6 mt-3">+260 97***** 400</div>
                                        <div className="col-lg-6 mt-3">Zamtel</div>
                                        <div className="col-lg-6 mt-3">+260 95***** 400</div>
                                        <div className="col-lg-6 mt-5"><Link  to={'/'} className="add-card-mobile">Add Number</Link></div>
                                        <div className="col-lg-6 mt-5 d-flex flex-sm-row flex-column">
                                            <img src={AIRTEL} alt="" />
                                            <img className='ms-3' src={MTN} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-12 col-lg-9">
                    <div className="col-12 col-lg-12">
                    <div className="my-loans mt-3 min-h d-flex flex-sm-row flex-column">
                        <div className="col-lg-9 form-account">
                        <h5 className='mb-4'>Account Details</h5>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="name">First Name</label>
                                        <input type="text" name="first_name" id="name" className='form-control'/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Last Name</label>
                                        <input type="text" name="first_name" id="name" className='form-control'/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="email">Email Address</label>
                                        <input type="email" name="email" id="email" className='form-control'/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Mobile Number</label>
                                        <input type="text" name="first_name" id="name" className='form-control'/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Alternat Number</label>
                                        <input type="text" name="first_name" id="name" className='form-control'/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="email">Identification Number</label>
                                        <input type="text" name="id" id="email" className='form-control'/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="email">Company.Organization</label>
                                        <input type="text" name="organization" id="email" className='form-control'/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="email">Address</label>
                                        <input type="text" name="address" id="email" className='form-control'/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Provice</label>
                                        <select name="province" id="" className='form-control'>
                                            <option value="Lusaka">Lusaka</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="name">City</label>
                                        <select name="city" id="" className='form-control'>
                                            <option value="Lusaka">Lusaka</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 text-center d-flex flex-sm-row flex-column align-items-center">
                            <div className='m-auto'>
                              <img src={PROFILE} alt="" className='mb-5'/>
                              <p className='mb-5'>Change Profile Picture</p>
                              <button className="btn btn-primary mt-5 profile-update-btn rounded-pill">Update</button>
                            </div>
                        </div>
                    </div>
                    <div className="my-loans mt-3 min-h d-flex flex-sm-row flex-column">
                        <div className="col-lg-9 form-account">
                        <h5 className='mb-4'>Bank Account Details</h5>
                            <div className="row">
                                <div className="col-lg-3 d-flex flex-sm-row flex-column">
                                    <img src={FNB} alt="" />
                                </div>
                                <div className="col-lg-9">
                                    <div className="col-lg-12">
                                        <p>Account Name</p>
                                        <h4>Kangwa Mubanga</h4>
                                    </div>
                                    <div className="col-lg-12 mb-3 mt-3">
                                        <p>Bank</p>
                                        <h4>First National Bank Zambia</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <p>Account Number</p>
                                        <h4>621234567890</h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-5">
                                    <span className='delete-bank-account'>Delete Account</span>
                                </div>
                                <div className="col-lg-6 mt-5">
                                    <span>Make Primary Account</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 text-center d-flex flex-sm-row flex-column align-items-center">
                            <div>
                                <h3 className=''>Add New <br /> Bank Account</h3>
                                <img src={APPLY} alt="" className='mb-t'/>
                            </div>
                        </div>
                    </div>
                      </div>
                    </div>
                    </div>
            </div>
        </div>
    )
}

export default Account;