import React from 'react';
import VISA from '../assets/img/visa.png';
import BlackLogo from '../assets/img/payment-methods.png';
//import Search from '../assets/img/search.png';
import { Search, Bell } from 'react-bootstrap-icons';
import KYC from '../assets/img/kyc.png';
import applyLoan from '../assets/img/apply.png';
import accountBnk from '../assets/img/account-bnk.png';
import DataTable from 'react-data-table-component';
import BlackLogox from '../assets/img/black-logo.png';
import Apple from '../assets/img/apple.png';
import GooglePlay from '../assets/img/google-play.png';
import Dashnav from './Dashnav';
function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }
  
  function toPages(pages) {
    const results = [];
  
    for (let i = 1; i < pages; i++) {
      results.push(i);
    }
  
    return results;
  }
  
  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.transaction_id,
      sortable: true
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true
    },
    {
      name: "Repayment",
      selector: (row) => row.repayment,
      sortable: true,
      right: true
    },
    {
      name: 'Date',
      selector: (row) => row.date,
      sortable: true
    }
  ];
  
  // RDT exposes the following internal pagination properties
const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage
  }) => {
    const handleBackButtonClick = () => {
      onChangePage(currentPage - 1);
    };
  
    const handleNextButtonClick = () => {
      onChangePage(currentPage + 1);
    };
  
    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };
  
    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;
  
    return (
      <nav>
        <ul className="pagination">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
            >
              Previous
            </button>
          </li>
          {pageItems.map((page) => {
            const className =
              page === currentPage ? "page-item active" : "page-item";
  
            return (
              <li key={page} className={className}>
                <button
                  className="page-link"
                  onClick={handlePageNumber}
                  value={page}
                >
                  {page}
                </button>
              </li>
            );
          })}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    );
  };
  
  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

const Dashbaordhome = () => {
    const movies = [{id:'01',transaction_id:'#23544',amount:'K2,790',repayment:'K3,230',date:'15/03/2022'},
                    {id:'01',transaction_id:'#23545',amount:'K2,790',repayment:'K3,230',date:'15/03/2022'}
                    ];
    return (
        <div className="app d-flex flex-sm-row flex-column">
            <Dashnav/>
            <div className="main-content">
                <div className="col-lg-12 float-end search-md">
                    <div className="input-group float-end">
                    <div className="float-end ms-1 me-2">
                        <Bell/>
                    </div>
                    <span className="input-group-append float-end">
                            <button className="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5" type="button">
                                <Search/>
                            </button>
                        </span>
                        <input type="text" name="searc" id="" className='form-control float-end dash-search border rounded-pill' placeholder='search here....'/>
                    </div>
                </div>
                <div className="col-12 col-lg-12 d-flex flex-sm-row flex-column">
                <div className="col-12 col-lg-3 left-dashboard container">
                        <h1 className='dashboard-title mb-4'>Hello, <span>Kangua</span></h1>
                        <div className="col-lg-12">
                            <p>Account Number</p>
                            <h4>q-0012345</h4>
                        </div>
                        <div className="col-lg-11 mt-5">
                            <p>My Loans</p>
                            <div className="my-loans mt-3">
                                <p className='mb-4 loans-p'>Balance <span>ZMW</span></p>
                                <div className="col-lg-12">
                                    <div className="align-items-end float-end">
                                        <p>Pay-off date</p>
                                        <p>0</p>
                                    </div>
                                </div>
                                <h3 className='mt-3 mb-3 dashboard-h3'>7,855</h3>
                                <span className='bottom-text'>Currently monthly payment</span>
                                
                            </div>
                        </div>
                        <div className="col-lg-11 mt-5">
                            <p>All current loans running</p>
                            <div className="my-loans mt-3">
                                <p className='mb-4 repayment'>Repayment <span>ZMW</span></p>
                                <div className="col-lg-12">
                                    <div className="align-items-end float-end">
                                        <p>Pay-off date</p>
                                        <p>0</p>
                                    </div>
                                </div>
                                <h3 className='mt-3 mb-3 dashboard-h3'>3,200</h3>
                                <span className='bottom-text'>Currently monthly payment</span>
                                
                            </div>
                        </div>
                        <div className="col-lg-11 mt-5">
                            <button className="btn btn-primary manual-repayment">
                                Manual Repayment
                            </button>
                        </div>
                        <div className="col-lg-11 mt-5">
                            <p>Payment methods</p>
                            <div className="my-loans mt-3">
                            <div className="col-lg-12">
                                    <div className="align-items-end float-end">
                                        <img src={BlackLogo} alt="" />
                                    </div>
                                </div>
                                <p className='mb-4 mycards'>my<span>CARDS</span></p>
                                <div className='bottom-text mt-5'>
                                    <img className='mt-5' src={VISA} alt="" />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-9">
                        <div className="col-12 col-lg-12 d-flex flex-sm-row flex-column align-items-center container">
                            <div className="col-12 col-lg-4 mt-5 ms-3 me-3">
                                <div className="my-loans mt-3 min-h">
                                        <div className="mb-5">
                                            <p>Transaction Summary</p>
                                            <span>(January)</span>
                                        </div>
                                        <div className="mb-5">
                                            <p>Current Loan Balance</p>
                                            <span className='bottom-stats'>ZMW 12,000</span>
                                        </div>
                                        <div className="mb-5">
                                            <p>Total Balance</p>
                                            <span className='bottom-stats'>ZMW 2,000</span>
                                        </div>
                                        <div>
                                            <p>Payment Method</p>
                                            <span className='bottom-stats'>MTN-ZM</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8 mt-5 me-3 min-h">
                            <div className="my-loans mt-3 min-h">
                                    <h5 className='mb-4'>Recent Transactions</h5>
                                    <DataTable
                                        columns={columns}
                                        data={movies}
                                        defaultSortFieldID={1}
                                        pagination
                                        paginationComponent={BootyPagination}
                                        selectableRows
                                        selectableRowsComponent={BootyCheckbox}
                                        />
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-lg-12 d-flex flex-sm-row flex-column align-items-center container right-side-dashboard">
                            <div className="col-12 col-lg-4 mt-5">
                                <div className="my-loans mt-3 text-center">
                                    <h3>Upload your KYC</h3>
                                    <img className='mt-4 mb-4' src={KYC} alt="" />
                                    <p>You will need an identity document, a selfie and your proof of address.</p>
                                    <button className="btn btn-primary btn-sm mt-4 kyc-button">Upload</button>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mt-5 ms-3 me-3">
                            <div className="my-loans mt-3 text-center">
                                    <h3>Add payment account</h3>
                                    <img className='mt-4 mb-4' src={accountBnk} alt="" />
                                    <p>Add a bank account or mobile money for payouts and repayments. You can add more than one account.</p>
                                    <button className="btn btn-primary btn-sm mt-4 kyc-button">Upload</button>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mt-5 green-border">
                            <div className="my-loans mt-3 text-center">
                                    <h1>Apply for a <br /> new loan</h1>
                                    <img className='mt-5 mb-4' src={applyLoan} alt="" />
                                </div>
                            </div>
                        </div>
                            <div className="col-12 col-lg-12 d-flex flex-sm-row flex-column align-items-center container green mt-5">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-12 align-items-center d-flex flex-sm-row flex-column mt-5">
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6 align-self-end">
                                            <h1 className="section-title-white">Loans and payouts <br /> made<span> simplerrr....</span></h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 align-items-center d-flex flex-sm-row flex-column mt-5 mb-5">
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6 align-items-center d-flex flex-sm-row flex-column">
                                            <img className="black-logo green-logo" src={BlackLogox} alt="" />
                                            <div className="ms-5">
                                                <div className="col-lg-8 mb-4">
                                                    <p style={{color:'#000'}}>The Qualis app is coming soon to Android and iOS. Now, that's access to quicker payouts.</p>
                                                </div>
                                                <div className="col-lg-12 float-start d-flex flex-sm-row flex-column align-items-center">
                                                    <img src={Apple} alt="" className='float-start'/>
                                                    <img src={GooglePlay} alt="" className='float-start'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                    </div>
                    </div>
            </div>
        </div>
    )
}

export default Dashbaordhome;