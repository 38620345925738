import logo from '../assets/img/qualis-logo.png';
import {Link,NavLink} from 'react-router-dom';
import {ArrowRight} from 'react-bootstrap-icons';
const Navbar = ()=> {
    return(
        <nav className="navbar navbar-expand-lg navbar-dark bg-gradient-green z-index-3 py-3">
    <div className="container">
        <Link to="/" className="navbar-brand text-white logo">
          <img src={logo} alt="dkjdlkj"></img>
        </Link>
        <button className="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
<span className="navbar-toggler-icon mt-2">
<span className="navbar-toggler-bar bar1"></span>
<span className="navbar-toggler-bar bar2"></span>
<span className="navbar-toggler-bar bar3"></span>
</span>
</button>
<div className="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0" id="navigation">
<ul className="navbar-nav navbar-nav-hover mx-auto">

<li className="nav-item mx-1 nav-item-active">
  <NavLink to={"/"} className={({isActive})=>(isActive?"link-active nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center":"link-unactive nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center")} role="button">
    Home
  </NavLink>
</li>
<li className="nav-item mx-1">
  <NavLink to="/about-us" className={({isActive})=>(isActive?"link-active nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center":"link-unactive nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center")} role="button">
    About Us
  </NavLink>
</li>
<li className="nav-item mx-1">
  <NavLink to={'/loan'} className={({isActive})=>(isActive?"link-active nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center":"link-unactive nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center")} role="button">
    Get a loan
  </NavLink>
</li>
<li className="nav-item mx-1">
  <NavLink to={"/community"} className={({isActive})=>(isActive?"link-active nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center":"link-unactive nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center")} role="button">
    Community
  </NavLink>
</li>
<li className="nav-item mx-1">
    <NavLink to={"/contact-us"} className={({isActive})=>(isActive?"link-active nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center":"link-unactive nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center")}>
        Contact
    </NavLink>
</li>
<li className='nav-item mx-1'>
    <NavLink className={({isActive})=>(isActive?"link-active nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center":"link-unactive nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center")} to={'/signup'}>
        <button className='btn btn-primary btn-sm sign-up-btn'>Sign up <ArrowRight/></button>
    </NavLink>
</li>
<li className="nav-item mx-1">
    <NavLink className={({isActive})=>(isActive?"link-active nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center":"link-unactive nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center")} to={'/login'}>
        Login
    </NavLink>
</li>
</ul>

</div>
    </div>
    </nav>
    );
}

export default Navbar;